import { useEffect, useMemo, useState } from "react";
import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdFirstPage,
  MdLastPage,
} from "react-icons/md";
import { AiOutlineUnorderedList } from "react-icons/ai";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { Button } from "components/Button";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDebounceValue } from "usehooks-ts";
import { columns } from "./complexTableColumns";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { tasksAPI } from "services/tasks";

export default function ComplexTable() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [searchDebounce] = useDebounceValue(search, 1000);

  const {
    data: tableData,
    isLoading,
    mutate,
  } = useSWR(
    `/list?searchBy=title&search=${searchDebounce}&page=${page}&limit=${limit}`,
    (url) => tasksAPI.getTasks(url)
  );

  useEffect(() => {
    handlePageChange(1);
  }, [searchDebounce]);

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > (tableData?.meta?.totalPages ?? 1)) return;

    setPage(newPage);
  };

  const table = useReactTable({
    data: tableData?.data || [],
    columns,
    state: {
      globalFilter: search,
    },
    onGlobalFilterChange: setSearch,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleDelete = async (id: string | undefined) => {
    if (!id) return;

    try {
      await tasksAPI.removeTask(id);
      mutate();
    } catch (error) {
      console.error(error);
    }
  };
  const handleChangeStatus = async (
    id: string | undefined,
    status: "ACTIVE" | "INACTIVE"
  ) => {
    if (!id) return;

    try {
      await tasksAPI.changeStatus(id, status);
      mutate();
    } catch (error) {
      console.error(error);
    }
  };

  const tableElements = useMemo(() => {
    return table.getRowModel().rows.map((row) => (
      <tr
        key={row.id}
        className="border-b border-gray-200 [&_td]:odd:dark:bg-navy-900"
      >
        {row.getVisibleCells().map((cell) => {
          return (
            <td
              key={cell.id}
              className="border-white/0 py-3 pr-4 first:rounded-l-lg last:rounded-r-lg"
            >
              {cell.column.id === "delete" || cell.column.id === "active" ? (
                <button
                  onClick={() =>
                    cell.column.id === "delete"
                      ? handleDelete(cell?.row?.original?.id)
                      : handleChangeStatus(
                          cell?.row?.original?.id,
                          cell?.row?.original?.status === "ACTIVE"
                            ? "INACTIVE"
                            : "ACTIVE"
                        )
                  }
                  className="flex items-center justify-center"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </button>
              ) : (
                flexRender(cell.column.columnDef.cell, cell.getContext())
              )}
            </td>
          );
        })}
      </tr>
    ));
  }, [table, tableData]);

  return (
    <>
      <div className="relative flex flex-col items-center justify-between gap-2 pt-4 sm:flex-row sm:gap-2">
        <input
          type="text"
          placeholder="Search by title"
          className="block h-12 w-full grow rounded-lg bg-lightPrimary px-4 text-base font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit sm:max-w-96"
          value={search}
          onChange={(e) => {
            mutate();
            setSearch(e.target.value);
          }}
        />
        <Button
          onClick={() => {
            navigate("/admin/tasks/new-task");
          }}
        >
          Create New
        </Button>
      </div>

      <div className="mt-8 flex min-h-48 grow flex-col overflow-x-auto">
        <table className="w-full">
          <thead className="sticky top-0 z-10">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={clsx(
                        "bg-white pb-2 pr-4 pt-4 text-start first:rounded-l-lg last:rounded-r-lg dark:bg-navy-800"
                      )}
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td className="absolute left-1/2 -translate-x-1/2" colSpan={8}>
                  <p className="flex justify-center py-10">
                    <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                  </p>
                </td>
              </tr>
            )}
            {!isLoading && tableData.data.length === 0 && (
              <tr>
                <td className="absolute left-1/2 -translate-x-1/2" colSpan={8}>
                  <p className="py-10 text-center font-bold uppercase">
                    <AiOutlineUnorderedList className="h-16 w-16 text-gray-600 opacity-30" />
                    <span className="text-sm text-gray-600">No data</span>
                  </p>
                </td>
              </tr>
            )}
            {!isLoading && tableData.data.length > 0 && tableElements}
          </tbody>
        </table>
      </div>
      <div className="mt-auto flex flex-col justify-start gap-2 pt-5 md:flex-row md:items-center">
        <Button disabled={page <= 1} onClick={() => handlePageChange(1)}>
          <MdFirstPage /> First
        </Button>
        <Button disabled={page <= 1} onClick={() => handlePageChange(page - 1)}>
          <MdArrowBackIosNew /> Previous
        </Button>
        <Button
          disabled={page >= (tableData?.meta?.totalPages ?? 1)}
          onClick={() => handlePageChange(page + 1)}
        >
          Next
          <MdArrowForwardIos />
        </Button>
        <Button
          disabled={page >= (tableData?.meta?.totalPages ?? 1)}
          onClick={() => handlePageChange(tableData?.meta?.totalPages ?? 1)}
        >
          Last
          <MdLastPage />
        </Button>
        <div className="flex flex-col items-center gap-4 text-gray-700 dark:text-gray-200 md:ml-auto md:flex-row">
          <div>Current page: {tableData?.meta?.currentPage ?? 1}</div>
          <div>Total items: {tableData?.meta?.totalItems ?? 0}</div>
          <div>Total pages: {tableData?.meta?.totalPages ?? 1}</div>
        </div>
      </div>
    </>
  );
}
