import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export const UserDetails: React.FC<{
  userId: number;
}> = ({ userId }) => {
  const navigate = useNavigate();
  const handleMoveBack = () => navigate("/admin/users");

/*   const data = {
    id: 1,
    name: "Jack",
    email: "jack@gmail.com",
    role: "Consumer",
    ref_qty: 0,
  }; */

  return (
    <div className="h-full w-full pt-4">
      <FaArrowLeftLong
        className="h-6 w-6 cursor-pointer opacity-75"
        onClick={handleMoveBack}
      />
      <div className="grid grid-cols-2 gap-20"></div>
    </div>
  );
};
