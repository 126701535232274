import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "components/Button";
import { CgCheck } from "react-icons/cg";
import { FaPlus } from "react-icons/fa";
import { snapshotAPI } from "services/snapshot";
import useSWR from "swr";
import { useMemo } from "react";

export const SnapshotDetails: React.FC<{}> = () => {
  const navigate = useNavigate();
  const handleMoveBack = () => navigate("/admin/snapshots");
  const { snapshotId } = useParams();

  const { data: taskData } = useSWR(
    `/results?searchBy=id&search=${snapshotId}`,
    (url) => snapshotAPI.getSnapshots(url)
  );

  const data = useMemo(
    () =>
      taskData?.data?.map((el) => {
       return {
        title: el.userTask.title,
        description: el.userTask.task.description,
        username: "XXX",
        //TODO request was changed
        // username: el.userTask.user.username,
        id: el.userTask.userTaskId,
        image: el.snapshotsUrls[0] || '',
      }})[0],
    [taskData]
  );

  const handleReviewSnapshot = async (type: "approve" | "reject") => {
    try {
      await snapshotAPI.snapshotActions(`/${type}/${data.id}`);
      handleMoveBack();
    } catch (error) {}
  };

  if (!data) return null;

  return (
    <div className="h-full w-full pt-4">
      <FaArrowLeftLong
        className="h-6 w-6 cursor-pointer opacity-75"
        onClick={handleMoveBack}
      />
      <div className="grid grid-cols-2 gap-20">
        <div className="flex w-full flex-col items-center justify-center gap-3 font-semibold">
          <img
            src={data?.image}
            alt="snapshot"
            className="h-[390px] object-cover"
          />
          <div>
            Username:{" "}
            <span className="font-normal text-brand-500">{data.username}</span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-xl font-semibold">{data?.title || "-"}</div>
          <div className="mt-4 text-base font-normal">
            {data?.description || "-"}
          </div>

          <div className="mt-8 grid grid-cols-2 gap-4">
            <Button
              onClick={() => handleReviewSnapshot("approve")}
              className="py-2text-white flex h-10 items-center justify-center rounded-md bg-green-500 px-4 font-semibold text-white hover:bg-green-600"
            >
              Accept
              <CgCheck className="h-8 w-8" />
            </Button>
            <Button
              onClick={() => handleReviewSnapshot("reject")}
              className="py-2text-white flex h-10 items-center justify-center rounded-md bg-red-500 font-semibold text-white hover:bg-red-600"
            >
              Reject
              <div className="ml-1 rotate-[134deg] transform">
                <FaPlus className="block h-4 w-4" />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
