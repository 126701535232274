import { createColumnHelper } from "@tanstack/react-table";
import { FaRegEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Snapshots } from "types/snapshots";

const columnHelper = createColumnHelper<Snapshots>();

export const columns = [
  columnHelper.accessor("title", {
    id: "title",
    filterFn: "includesString",
    enableSorting: false,
    header: () => (
      <p className="w-[250px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        title
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="w-[250px] cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("description", {
    id: "description",
    enableSorting: false,
    header: () => (
      <p className="w-[350px] overflow-hidden truncate text-sm font-bold uppercase text-gray-600 dark:text-white">
        description
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="w-[350px] cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("username", {
    id: "reward",
    enableSorting: false,
    header: () => (
      <p className="min-w-[150px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        username
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("details", {
    id: "details",
    enableSorting: false,
    header: () => <></>,
    cell: (info) => (
      <div
        className="flex items-center justify-center"
        onClick={() => console.log(info?.row?.original?.id?.toString())}
      >
        <Link to={info?.row?.original?.id ? `${info.row.original.id}` : "#"}>
          <FaRegEye className="h-5 w-5 cursor-pointer text-brand-700" />
        </Link>
      </div>
    ),
  }),
];
