import React, { useCallback, useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Card from "components/card";
import defaultImage from "assets/img/default/default_mage.png";
import { Task, TaskRefRequest, TaskTgRequest } from "types/tasks";
import { useForm } from "react-hook-form";
import InputField from "components/fields/InputField";
import Select from "components/fields/Select";
import Checkbox from "components/checkbox";
import useSWR from "swr";
import { tasksAPI } from "services/tasks";
import { uploadImage } from "services/uploadImage";
import { v4 as uuidv4 } from "uuid";

interface TaskDetailsProps {
  newMode?: boolean;
}

export const TaskDetails: React.FC<TaskDetailsProps> = ({ newMode }) => {
  const [image, setImage] = useState(defaultImage);
  const [files, seFiles] = useState<File[] | null>(null);
  const { taskId } = useParams();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      seFiles(acceptedFiles);
      const file = acceptedFiles[0];
      const imageUrl = URL.createObjectURL(file);
      setImage(imageUrl);
    }
  }, []);

  const { data: taskData } = useSWR(
    `/list?searchBy=id&search=${taskId}`,
    (url) => !newMode && tasksAPI.getTasks(url)
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const navigate = useNavigate();
  const handleMoveBack = () => navigate("/admin/tasks");

  const defaultData = {
    type: "TELEGRAM",
    title: "",
    description: "",
    status: "ACTIVE",
    rewardPointsAmount: 0,
    rewardTicketsAmount: 0,
    redirectUrl: "",
    projectId: "",
    telegramResourceId: 0,
    inviteCount: 0,
  };

  useEffect(() => {
    taskData?.data?.[0]?.iconUrl && setImage(taskData?.data?.[0]?.iconUrl);
  }, [taskData]);

  const form = useForm<Task>({
    defaultValues: {
      ...defaultData,
    },
    values: {
      ...taskData?.data?.[0],
    },
  });

  const statusValue = form.watch("status");
  const taskType = form.watch("type");
  const isActive = statusValue === "ACTIVE";

  const handleSubmit = async (data: Task) => {
    try {
      //TODO remove temporary link
      let iconUrl =
        null;
      // let iconUrl = image;
      //TODO next req returns 401, need fix it on server
      if (files && files.length > 0) {
        const res = await uploadImage.upload(files);
        if (res?.[0].imageUrl) iconUrl = res?.[0].imageUrl;
      }

      let task = {
        ...data,
        redirectUrl: data.redirectUrl.trim(),
        description: data.description.trim(),
        rewardPointsAmount: +data.rewardPointsAmount,
        rewardTicketsAmount: +data.rewardTicketsAmount,
        iconUrl,
      };

      if (!newMode) {
        await tasksAPI.updateTask(task);

        return;
      }

      let newTaskBody: TaskTgRequest | Task | TaskRefRequest;

      if (taskType === "TELEGRAM") {
        newTaskBody = {
          task: { ...task, projectId: uuidv4() },
          telegramTask: {
            telegramResourceId: +data.telegramResourceId,
            type: "SUBSCRIBE",
          },
        };
      }
      if (taskType === "REFERRAL") {
        newTaskBody = {
          task: { ...task, projectId: uuidv4() },
          inviteCount: +data.inviteCount,
        };
      }
      if (taskType === "COMMON" || taskType === "SNAPSHOT") {
        newTaskBody = { ...task, projectId: uuidv4() };
      }

      await tasksAPI.createTask(newTaskBody, data.type.toLowerCase());

      form.reset();
    } catch (error) {
      console.error(error);
    }
  };

  const typeOptions = ["TELEGRAM", "SNAPSHOT", "COMMON", "REFERRAL"];

  return (
    <div className="h-full w-full pt-4">
      <FaArrowLeftLong
        className="h-6 w-6 cursor-pointer opacity-75"
        onClick={handleMoveBack}
      />
      <div className="grid grid-cols-[1fr,2fr] gap-10">
        <div className="flex flex-col items-center justify-between gap-6">
          <Card
            {...getRootProps()}
            className="flex h-[300px] w-[300px] cursor-pointer flex-col items-center justify-center border-2 border-dashed border-gray-300 bg-gray-50 p-6"
          >
            <input {...getInputProps()} />
            <img
              src={image}
              alt="snapshot"
              className={`h-[220px] w-[220px] object-cover ${
                !image && "opacity-60"
              }`}
            />
            <div className="mt-4 text-center font-normal text-brand-500">
              Browse or drop your image here
            </div>
          </Card>
        </div>

        <>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="relative mx-auto min-h-full w-full max-w-full flex-col justify-between"
          >
            <div className="grid grid-cols-2 gap-4">
              {/* Type */}
              <Select
                variant="primary"
                label="Type"
                {...form.register("type", { required: true })}
              >
                {typeOptions.map((option) => (
                  <option className="dark:text-[#000]" key={option}>
                    {option}
                  </option>
                ))}
              </Select>
              {/* Title */}
              <InputField
                variant="primary"
                extra="mb-3"
                label="Title"
                id="title"
                type="text"
                placeholder=""
                required
                {...form.register("title", { required: true })}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              {/* Reward Points Amount */}
              <InputField
                variant="primary"
                extra="mb-3"
                label="Reward Points Amount"
                id="rewardPointsAmount"
                type="number"
                placeholder=""
                required
                {...form.register("rewardPointsAmount", { required: true })}
              />
              {/* Reward Tickets Amount */}
            </div>

            {taskType && taskType === "TELEGRAM" && (
              <div className="grid grid-cols-2 gap-4">
                {/* Telegram Resource Id */}
                <InputField
                  variant="primary"
                  extra="mb-3"
                  label="Telegram Resource Id"
                  id="telegramResourceId"
                  type="text"
                  placeholder=""
                  required
                  {...form.register("telegramResourceId", { required: true })}
                />
              </div>
            )}

            {taskType && taskType === "REFERRAL" && (
              <div className="grid grid-cols-2 gap-4">
                {/* Invite Count */}
                <InputField
                  variant="primary"
                  extra="mb-3"
                  label="Invite Count"
                  id="inviteCount"
                  type="number"
                  placeholder=""
                  required
                  {...form.register("inviteCount", { required: true })}
                />
              </div>
            )}

            <div className="grid grid-cols-2 gap-4">
              <InputField
                variant="primary"
                extra="mb-3"
                label="Description"
                id="description"
                type="text"
                placeholder=""
                required
                {...form.register("description", { required: true })}
              />
              <InputField
                variant="primary"
                extra="mb-3"
                label="Redirect Url"
                id="redirectUrl"
                type="text"
                placeholder=""
                required
                {...form.register("redirectUrl", { required: true })}
              />
            </div>

            <div className=" mt-2 flex w-full items-center gap-8">
              <div className="flex items-center gap-2">
                <span className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
                  Active
                </span>
                <Checkbox
                  className="h-5 w-5"
                  checked={isActive}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    form.setValue(
                      "status",
                      e.target.checked ? "ACTIVE" : "INACTIVE"
                    )
                  }
                />
              </div>
              <button
                type="submit"
                disabled={form.formState.isSubmitting}
                className="linear w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:opacity-50 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                {newMode ? "Create" : "Update"}
              </button>
            </div>

            {form.formState?.errors?.custom && (
              <p className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.custom.message}
              </p>
            )}
          </form>
        </>
      </div>
    </div>
  );
};
