export interface AuthResponse {
  tokens: {
    access: string,
  }
}

export enum AdminRole {
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export interface Admin {
  id: string;
  email: string;
  role: AdminRole;
}