import { createColumnHelper } from "@tanstack/react-table";
import { FaRegEye } from "react-icons/fa";
import { Users } from "types/users";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper<Users>();

export const columns = [
  columnHelper.accessor("username", {
    id: "userName",
    filterFn: "includesString",
    enableSorting: false,
    header: () => (
      <p className="w-[350px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        username
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="w-[350px] cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("id", {
    id: "id",
    enableSorting: false,
    header: () => (
      <p className="w-[90px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        id
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="w-[90px] cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  // columnHelper.accessor("email", {
  //   id: "email",
  //   enableSorting: false,
  //   header: () => (
  //     <p className="w-[250px] text-sm font-bold uppercase text-gray-600 dark:text-white">
  //       email
  //     </p>
  //   ),
  //   cell: (info) => (
  //     <p
  //       onClick={() => info.table.setGlobalFilter(info.getValue())}
  //       className="w-[250px] cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
  //     >
  //       {info.getValue()}
  //     </p>
  //   ),
  // }),
  // columnHelper.accessor("ref_qty", {
  //   id: "ref_qty",
  //   enableSorting: false,
  //   header: () => (
  //     <p className="w-[150px] text-sm font-bold uppercase text-gray-600 dark:text-white">
  //       ref quantity
  //     </p>
  //   ),
  //   cell: (info) => (
  //     <p
  //       onClick={() => info.table.setGlobalFilter(info.getValue())}
  //       className="w-[150px] cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
  //     >
  //       {info.getValue()}
  //     </p>
  //   ),
  // }),
  // columnHelper.accessor("details", {
  //   id: "details",
  //   enableSorting: false,
  //   header: () => <></>,
  //   cell: (info) => (
  //     <div
  //       className="flex items-center justify-center"
  //       onClick={() => console.log(info?.row?.original?.id?.toString())}
  //     >
  //       <Link to={info?.row?.original?.id ? `${info.row.original.id}` : "#"}>
  //         <FaRegEye className="h-5 w-5 cursor-pointer text-brand-700" />
  //       </Link>
  //     </div>
  //   ),
  // }),
];
