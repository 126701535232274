import Widget from "components/widget/Widget";
import ComplexTable from "./components/ComplexTable";
import { MdAdd } from "react-icons/md";
import useSWR from "swr";
import { adminAPI } from "services/admin";
import { AdminForm } from "./components/AdminForm";
import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProfileOverview = () => {
  const { data, isLoading, mutate } = useSWR(`/admins`, () =>
    adminAPI.getAdmins()
  );
  const navigate = useNavigate();

  useLayoutEffect(() => {
    adminAPI.hasAccess().then((hasAccess) => {
      if (!hasAccess) {
        navigate("/");
      }
    });
  }, []);

  return (
    <div className="mt-3">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
        <AdminForm admins={data} onSuccess={() => mutate()}>
          <button className="outline-none rounded-2xl">
            <Widget
              icon={<MdAdd className="h-7 w-7" />}
              title={""}
              subtitle={"Add new admin"}
            />
          </button>
        </AdminForm>
      </div>
      <div className="mt-3 grid h-full grid-cols-1 gap-4">
        <ComplexTable
          tableData={data}
          isLoading={isLoading}
          onSuccess={() => mutate()}
        />
      </div>
    </div>
  );
};

export default ProfileOverview;
