import Card from "components/card";
import ComplexTable from "./components/ComplexTable";
import { SnapshotDetails } from "./components/snapshotDetails";
import { Route, Routes } from "react-router-dom";

const Tasks = () => {
  return (
    <Card
      extra={
        "w-full h-[800px]  md:h-fit px-6 pb-6 sm:overflow-x-auto rounded-[15px]"
      }
    >
      <Routes>
        <Route path="/" element={<ComplexTable />} />

        <Route path=":snapshotId" element={<SnapshotDetails/>} />
      </Routes>
    </Card>
  );
};

export default Tasks;



