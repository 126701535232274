import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { PrivateRoute } from "components/PrivateRoute";
import { useEffect } from "react";
import { authAPI } from "services/auth";
const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    authAPI.refresh().catch(() => {
      localStorage.removeItem("accessToken");
      authAPI.logout();
      navigate("/auth/sign-in");
    });
  }, []);
  useEffect(() => {
    const isDark = localStorage.getItem("darkmode");
    if (isDark === "true") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, []);

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={
          <PrivateRoute>
            <AdminLayout />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Navigate to="/admin" replace />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default App;
