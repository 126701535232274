



import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { UploadedImage } from 'types/tasks';
import { getAccessToken } from "utils/auth";

export class UploadImage {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async upload(files: File[]) {
    try {
      const accessToken = getAccessToken();

      const { data } = await this._api.post<UploadedImage[]>(
				'api/files/upload',
				[...files],
				{
        headers: {
          authorization: accessToken,
        },
      }
		);

      return data;
    } catch (error) {
      console.warn(error);
    }
  }
}

export const uploadImage = new UploadImage();
