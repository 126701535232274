import { Route, Routes } from "react-router-dom";
import ComplexTable from "./components/ComplexTable";
import { TaskDetails } from "./components/taskDetails";
import Card from "components/card";

const Tasks = () => {
  return (
    <Card
      extra={
        "w-full h-[800px]  md:h-fit px-6 pb-6 sm:overflow-x-auto rounded-[15px]"
      }
    >
      <Routes>
        <Route path="/" element={<ComplexTable />} />

        <Route path=":taskId" element={<TaskDetails/>} />

        <Route
          path="/new-task"
          element={<TaskDetails newMode />}
        />
      </Routes>
    </Card>
  );
};

export default Tasks;
