import { AxiosError } from "axios";
import InputField from "components/fields/InputField";
import { useLayoutEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { authAPI } from "services/auth";
import { getAccessToken } from "utils/auth";
import { FormSchema } from "./form";

export default function SignIn() {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    try {
      getAccessToken();
      navigate("/");
    } catch (error) {
      console.warn(error);
    }
  }, [navigate]);

  const form = useForm<FormSchema>({
    defaultValues: {
      email: "",
      password: "",
      keepMeLoggedIn: false,
    },
  });

  const handleSubmit = async (data: FormSchema) => {
    try {
      localStorage.setItem("accessToken", "accessToken");
      await authAPI.auth(data.email, data.password);
      navigate("/");
    } catch (error) {
      console.warn(error);
      if (error instanceof AxiosError) {
        form.setError("root", {
          type: "custom",
          message: error.response?.data.message || "Something went wrong",
        });
      }
    }
  };

  return (
    <div className="mx-auto mb-16 mt-16 flex h-full w-full grow items-center justify-center px-2 md:px-0 lg:mb-10 lg:items-center">
      {/* Sign in section */}
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="mx-auto mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:max-w-[420px] lg:pl-0"
      >
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your login and password
        </p>
        {/* Login */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Login*"
          id="login"
          type="text"
          placeholder=""
          required
          {...form.register("email", { required: true })}
        />

        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          id="password"
          type="password"
          placeholder=""
          required
          {...form.register("password", { required: true })}
        />
        {form.formState?.errors?.custom && (
          <p className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.custom.message}
          </p>
        )}

        <button
          type="submit"
          disabled={form.formState.isSubmitting}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:opacity-50 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Login
        </button>
      </form>
    </div>
  );
}
