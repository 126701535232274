

import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { Task, TaskRefRequest, TaskResponce, TaskTgRequest } from 'types/tasks';
import { getAccessToken } from "utils/auth";

export class TasksAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL + 'api/tasks',
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async getTasks(url: string) {
    try {
      const accessToken = getAccessToken();

      const { data } = await this._api.get<TaskResponce>(url, {
        headers: {
          authorization: accessToken,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
  }
  async updateTask(task: Task) {
      const accessToken = getAccessToken();

      const { data } = await this._api.patch<Task>(
				`/${task.id}`,
				task,
				{
					headers: {
						authorization: accessToken,
					},
        },
		);

      return data;
  }
  async changeStatus(id: string, status: 'ACTIVE' | 'INACTIVE') {
      const accessToken = getAccessToken();

      const { data } = await this._api.patch<Task>(
				`/${id}`,
				{
					status,
				},
				{
					headers: {
						authorization: accessToken,
					},
        },
		);

      return data;
  }
  async createTask(body: TaskTgRequest | Task | TaskRefRequest, type: string) {
      const accessToken = getAccessToken();

      const { data } = await this._api.post<Task>(
				`/${type}`,
				body,
				{
					headers: {
						authorization: accessToken,
					},
        },
		);

      return data;
  }
  async removeTask(id: string) {
      const accessToken = getAccessToken();

      const { data } = await this._api.delete(
				`/${id}`,
				{
        headers: {
          authorization: accessToken,
        },
      },
		);

      return data;
  }
}

export const tasksAPI = new TasksAPI();
