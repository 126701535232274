import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { AuthResponse } from "types/auth";
import { getAccessToken } from "utils/auth";

export class AuthAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async requestOTP(email: string) {
    // const url = "/api/otp/request/admin-login";
    // await this._api.post(url, {
    //   email: email.toLowerCase(),
    // });
  }

  async auth(email: string, password: string, keepMeLoggedIn?: boolean) {
    const url = "/api/auth/admin";
    const { data } = await this._api.post<AuthResponse>(url, {
      login: email.toLowerCase(),
      password,
    });
    localStorage.setItem("accessToken", data.tokens.access);
  }

  async refresh() {
    const url = "/api/auth/refresh";
    const { data } = await this._api.post<{ access: string }>(url);
    localStorage.setItem("accessToken", data.access);
  }

  async logout() {
    const url = "/api/auth/logout";
    await this._api.post(url);

    localStorage.removeItem("accessToken");
    sessionStorage.removeItem("accessToken");
  }
}

export const authAPI = new AuthAPI();
