import { createColumnHelper } from "@tanstack/react-table";
import { Admin } from "types/auth";

const columnHelper = createColumnHelper<Admin>();

export const columns = [
  columnHelper.accessor("email", {
    id: "address",
    header: () => (
      <p className="min-w-[350px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Email
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("role", {
    id: "status",
    header: () => (
      <p className="min-w-[100px] text-sm font-bold text-gray-600 dark:text-white">
        STATUS
      </p>
    ),
    cell: (info) => (
      <div className="flex items-center">
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue().replace("_", " ")}
        </p>
      </div>
    ),
  }),
];
