import { forwardRef, HTMLProps } from "react";

interface CardProps extends HTMLProps<HTMLDivElement> {
  variant?: string;
  extra?: string;
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  (props, ref) => {
    const { variant, extra, children, ...rest } = props;

    return (
      <div
        ref={ref} 
        className={`relative flex flex-col rounded-[10px] border-[1px] border-gray-200 bg-white bg-clip-border shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none ${extra}`}
        {...rest} 
      >
        {children}
      </div>
    );
  }
);

export default Card;
