import { ButtonHTMLAttributes, forwardRef } from "react";

export const Button = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  return (
    <button
      type="button"
      ref={ref}
      className="flex items-center gap-2 rounded-xl bg-brand-500
                  px-5 py-2 text-base font-medium text-white transition
                  duration-200 hover:bg-brand-600 active:bg-brand-700
                  disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400
                  dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
      {...props}
    >
      {children}
    </button>
  );
});
