// Admin Imports
import MainDashboard from "views/admin/dashboard";
import Profile from "views/admin/adminSEttings";
import Users from "views/admin/users";
import Snapshots from "views/admin/snapshots";
import Tasks from "views/admin/tasks";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import { FaTasks } from "react-icons/fa";
import { MdHome, MdLock, MdPerson, MdFullscreen } from "react-icons/md";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users/*",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Users />,
    secondary: true,
  },
  {
    name: "Tasks",
    layout: "/admin",
    path: "tasks/*",
    icon: <FaTasks className="h-6 w-6" />,
    component: <Tasks />,
    secondary: true,
  },
  {
    name: "Snapshots",
    layout: "/admin",
    path: "snapshots/*",
    icon: <MdFullscreen className="h-6 w-6" />,
    component: <Snapshots />,
  },
  {
    name: "Admin settings",
    layout: "/admin",
    path: "admin-settings",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
