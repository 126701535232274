import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { Admin, AdminRole } from "types/auth";
import { getAccessToken } from "utils/auth";

export class AdminAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL + "api/admins",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async hasAccess(): Promise<boolean> {
    try {
      await this._api.get("/", {
        headers: { authorization: getAccessToken() },
      });
      return true;
    } catch (error) {
      console.warn(error);
    }
    return false;
  }

  async getAdmins(): Promise<Admin[]> {
    try {
      const { data } = await this._api.get<Admin[]>("/", {
        headers: { authorization: getAccessToken() },
      });
      return data;
    } catch (error) {
      console.warn(error);
    }
    return [];
  }

  async createAdmin(email: string, role: AdminRole): Promise<Admin | null> {
    const { data } = await this._api.post<Admin>(
      "/",
      { email: email.toLowerCase(), role },
      {
        headers: { authorization: getAccessToken() },
      }
    );
    return data;
  }

  async deleteAdmin(adminId: Admin["id"]): Promise<boolean> {
    try {
      await this._api.delete(`/${adminId}`, {
        headers: { authorization: getAccessToken() },
      });
      return true;
    } catch (error) {
      console.warn(error);
    }
    return false;
  }

  async updateAdmin(
    adminId: Admin["id"],
    email: string,
    role: AdminRole
  ): Promise<Admin | null> {
    const { data } = await this._api.patch<Admin>(
      `${adminId}`,
      {
        email: email.toLowerCase(),
        role,
      },
      {
        headers: { authorization: getAccessToken() },
      }
    );
    return data;
  }
}

export const adminAPI = new AdminAPI();
