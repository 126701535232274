



import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { SnapshotRequest, SnapshotResponse } from 'types/snapshots';
import { getAccessToken } from "utils/auth";

export class SnapshotAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL + 'api/snapshot-tasks',
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async getSnapshots(url: string) {
    try {
      const accessToken = getAccessToken();

      const { data } = await this._api.get<SnapshotResponse>(url, {
        headers: {
          authorization: accessToken,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
  }
  async createSnapshot(url: string, body: SnapshotRequest) {
    try {
      const accessToken = getAccessToken();

      await this._api.post(
				url,
				body,
				{
        headers: {
          authorization: accessToken,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  }
  async snapshotActions(url: string) {
		const accessToken = getAccessToken();

		await this._api.post(
			url,
			{},
			{
			headers: {
				authorization: accessToken,
			},
		});
    await this.getSnapshots(`/results?page=${1}&limit=${10}`);
  }
}

export const snapshotAPI = new SnapshotAPI();
