import { MdGroup } from "react-icons/md";

import Widget from "components/widget/Widget";
import useSWR from "swr";
import { analyticsAPI } from "services/analytics";

const Dashboard = () => {
  const { data, isLoading } = useSWR("/api/users/count", (url) =>
    analyticsAPI.getUserCount(url)
  );

  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
        <Widget
          icon={<MdGroup className="h-6 w-6" />}
          title={"Total users"}
          subtitle={isLoading ? "Loading..." : data?.count ?? 0}
        />
      </div>
    </div>
  );
};

export default Dashboard;
