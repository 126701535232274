import { useLocation } from "react-router-dom";
import ComplexTable from "./components/ComplexTable";
import Card from "components/card";
import { UserDetails } from "./components/userDetails";

const Users = () => {
  let { pathname } = useLocation();
  const pathParts = pathname.split("/");
  const userId = Number(pathParts[pathParts?.length - 1]) || null;
  return (
    <Card
      extra={
        "w-full h-[800px]  md:h-[500px] px-6 pb-6 sm:overflow-x-auto rounded-[15px]"
      }
    >
      {userId ? <UserDetails userId={userId} /> : <ComplexTable />}
    </Card>
  );
};

export default Users;
