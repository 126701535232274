import { createColumnHelper } from "@tanstack/react-table";
import Checkbox from "components/checkbox";
import { FaRegEye } from "react-icons/fa";
import { MdOutlineDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { Task } from "types/tasks";

const columnHelper = createColumnHelper<Task>();

export const columns = [
  columnHelper.accessor("title", {
    id: "title",
    filterFn: "includesString",
    enableSorting: false,
    header: () => (
      <p className="w-[340px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        title
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="w-[340px] cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("type", {
    id: "type",
    enableSorting: false,
    header: () => (
      <p className="w-[220px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        type
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="w-[220px] cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("rewardPointsAmount", {
    id: "reward",
    enableSorting: false,
    header: () => (
      <p className="w-[150px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        reward
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="w-[150px] cursor-pointer truncate text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("status", {
    id: "active",
    enableSorting: false,
    header: () => (
      <p className="flex justify-center truncate text-sm font-bold uppercase text-gray-600 dark:text-white">
        hidden
      </p>
    ),
    cell: (info) => (
      <div className="flex items-center justify-center">
        <Checkbox
          defaultChecked={info.getValue() === "ACTIVE"}
        />
      </div>
    ),
  }),
  columnHelper.accessor("delete", {
    id: "delete",
    enableSorting: false,
    header: () => <></>,
    cell: (info) => (
      <div className="flex cursor-pointer items-center">
        <MdOutlineDelete className="h-5 w-5 text-red-600" />
      </div>
    ),
  }),
  columnHelper.accessor("details", {
    id: "details",
    enableSorting: false,
    header: () => <></>,
    cell: (info) => (
      <div
        className="flex items-center justify-center"
        onClick={() => console.log(info?.row?.original?.id?.toString())}
      >
        <Link to={info?.row?.original?.id ? `${info.row.original.id}` : "#"}>
          <FaRegEye className="h-5 w-5 cursor-pointer text-brand-700" />
        </Link>
      </div>
    ),
  }),
];
