import Card from "components/card";

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Admin } from "types/auth";
import { useDebounceValue } from "usehooks-ts";
import { columns } from "./complexTableColumns";
import { Button } from "components/Button";
import { MdDelete, MdEdit } from "react-icons/md";
import { DangerButton } from "components/Button/Danger";
import { adminAPI } from "services/admin";
import { AdminForm } from "./AdminForm";

export default function ComplexTable({
  tableData,
  isLoading,
  onSuccess,
}: {
  tableData: Admin[];
  isLoading: boolean;
  onSuccess: () => void;
}) {
  const [data] = useDebounceValue(tableData ?? [], 100);

  const table = useReactTable({
    data: data,
    columns,
    state: {},
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Card extra={"w-full px-6 pb-6 sm:overflow-x-auto rounded-[15px]"}>
      <div className="mt-8 flex grow flex-col overflow-x-auto">
        <table className="w-full">
          <thead className="sticky top-0">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className={clsx(
                        "bg-white pb-2 pr-4 pt-4 text-start first:rounded-l-lg last:rounded-r-lg dark:bg-navy-800",
                        header.column.getCanSort() && "cursor-pointer"
                      )}
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={8}>
                  <p className="flex justify-center py-10">
                    <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                  </p>
                </td>
              </tr>
            )}
            {!!tableData && !isLoading && tableData.length <= 0 && (
              <tr>
                <td colSpan={8}>
                  <p className="py-10 text-center font-bold uppercase">
                    No data
                  </p>
                </td>
              </tr>
            )}
            {!isLoading &&
              !!tableData &&
              tableData.length > 0 &&
              table.getRowModel().rows.map((row) => {
                return (
                  <tr
                    key={row.id}
                    className="[&_td]:odd:bg-gray-50 [&_td]:odd:dark:bg-navy-900"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3 pr-4 first:rounded-l-lg last:rounded-r-lg"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                    <td>
                      <div className="flex items-center justify-end gap-2">
                        <AdminForm
                          admins={tableData}
                          admin={row.original}
                          onSuccess={onSuccess}
                        >
                          <Button>
                            <MdEdit />
                            Edit
                          </Button>
                        </AdminForm>
                        <DangerButton
                          onClick={async () => {
                            if (
                              window.confirm(
                                `Are you sure you want to delete this admin? (${row.original.email})`
                              )
                            ) {
                              await adminAPI.deleteAdmin(row.original.id);
                              onSuccess();
                            }
                          }}
                        >
                          <MdDelete />
                          Delete
                        </DangerButton>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Card>
  );
}
