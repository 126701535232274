import * as Dialog from "@radix-ui/react-dialog";
import InputField from "components/fields/InputField";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";
import { Admin, AdminRole } from "types/auth";
import { AdminFormScheme } from "./scheme";
import Select from "components/fields/Select";
import { adminAPI } from "services/admin";
import { AxiosError } from "axios";

interface AdminFormProps {
  admin?: Admin;
  admins: Admin[];
  children?: React.ReactNode;
  onSuccess: () => void;
}

export function AdminForm({ admins, admin, children, onSuccess }: AdminFormProps) {
  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<AdminFormScheme>({
    values: {
      email: admin?.email || "",
      role: admin?.role || AdminRole.ADMIN,
    },
  });

  const handleSubmit = async (data: AdminFormScheme) => {
    try {
      if (!admin) {
        await adminAPI.createAdmin(data.email, data.role);
      } else {
        if (admins.some((el) => el.email === data.email && el.id !== admin.id))
          throw new Error("Admin already exists");

        await adminAPI.updateAdmin(admin.id, data.email, data.role);
      }

      handleOpenChange(false);
      onSuccess();
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError("root", {
          type: "manual",
          message: error.response?.data.message || error.message,
        });
      } else if (error instanceof Error) {
        form.setError("root", {
          type: "manual",
          message: error.message,
        });
      }

      console.warn(error);
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);

    if (isOpen) return;

    form.reset();
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={handleOpenChange} modal>
        <Dialog.Trigger asChild>{children}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white">
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Dialog.Title className="text-mauve12 mb-10 text-[17px] font-medium">
                {!admin ? "Add new" : "Edit"} admin
              </Dialog.Title>
              <div className="max-h-[480px] grow overflow-y-auto overflow-x-hidden px-px">
                <FormProvider {...form}>
                  <fieldset className="mb-[15px] flex flex-col">
                    <InputField
                      variant="auth"
                      state={form.formState.errors.email && "error"}
                      extra="w-full mb-3"
                      label="Email:"
                      placeholder="example@example.com"
                      id="email"
                      type="email"
                      {...form.register("email", {
                        required: "This field is required",
                      })}
                    />
                    {form.formState.errors.email && (
                      <span className="text-sm text-red-500 dark:text-red-400">
                        {form.formState.errors.email.message}
                      </span>
                    )}
                  </fieldset>
                  <fieldset className="mb-[15px] flex flex-col">
                    <Select
                      variant="auth"
                      label="Role:"
                      {...form.register("role", {
                        required: "This field is required",
                      })}
                    >
                      <option
                        className="dark:text-[#000]"
                        value={AdminRole.ADMIN}
                      >
                        ADMIN
                      </option>
                      <option
                        className="dark:text-[#000]"
                        value={AdminRole.SUPER_ADMIN}
                      >
                        SUPER ADMIN
                      </option>
                    </Select>
                    {form.formState.errors.role && (
                      <span className="text-sm text-red-500 dark:text-red-400">
                        {form.formState.errors.role.message}
                      </span>
                    )}
                  </fieldset>
                </FormProvider>
              </div>
              <div className="mt-[10px] flex flex-col">
                {form.formState.errors.root && (
                  <span className="text-sm capitalize text-red-500 dark:text-red-400">
                    {form.formState.errors.root.message}
                  </span>
                )}
                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500
                  py-[12px] text-base font-medium text-white transition
                  duration-200 hover:bg-brand-600 active:bg-brand-700
                  disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400
                  dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled={
                    form.formState.isSubmitting || !form.formState.isDirty
                  }
                >
                  {form.formState.isSubmitting ? "Saving..." : "Save changes"}
                </button>
              </div>
            </form>
            <Dialog.Close asChild>
              <button
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
